@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  /*-Normal-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 500;
  /*-Medium-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 700;
  /*-Bold-*/
  src: url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff") format("woff"), url("../font/NotoSerifCJKjp/NotoSerifCJKjp-Bold.woff2") format("woff2");
}

.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #583822;
  font-style: normal;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 10px 20px;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 20px;
  }
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 100px;
}

@media only screen and (max-width: 834px) {
  #contents_wrap {
    margin-bottom: 50px;
  }
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

b {
  font-weight: 500;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif Japanese", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans Japanese", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
}

@media only screen and (max-width: 834px) {
  header#global_header {
    padding-top: 68px;
    border-top: 0;
  }
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.fixed {
  position: fixed;
  z-index: 11;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.chuumon {
  background: #a9a9a9;
}

.chuumon i {
  padding-left: 7px;
}

.chuumon a {
  color: #fff;
  padding: 35px;
  display: block;
}

@media only screen and (max-width: 640px) {
  .chuumon a {
    padding: 16px;
  }
}

.saichuumon {
  background: #a9a9a9;
  border-left: 1px solid #fff;
}

.saichuumon i {
  padding-left: 7px;
}

.saichuumon a {
  color: #fff;
  padding: 35px;
  display: block;
}

@media only screen and (max-width: 640px) {
  .saichuumon a {
    padding: 16px;
  }
}

#header {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background: #fff;
}

#header .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  #header .inner {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  #header {
    box-shadow: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header {
    box-shadow: none;
  }
}

#header .flxL {
  width: 25%;
}

@media only screen and (max-width: 834px) {
  #header .flxL {
    width: 70%;
    margin: 0 auto 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header .flxL {
    width: 32%;
  }
}

#header .flxL #siteID img {
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  #header .flxL #siteID img {
    max-width: 100%;
  }
}

#header .flxR {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60%;
}

@media only screen and (max-width: 640px) {
  #header .flxR {
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .flxR {
    width: 58%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header .flxR {
    width: 70%;
  }
}

#header .flxR .subnav {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 4;
}

@media only screen and (max-width: 834px) {
  #header .flxR .subnav {
    margin-top: 10px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100% !important;
    justify-content: flex-start;
  }
}

#header .flxR .subnav .left .tel2 {
  color: #fff;
  font-size: 24px;
  padding-right: 5px;
  display: flex;
  text-align: left;
  align-items: center;
  background: #f39700;
  border-radius: 50px;
  padding: 15px;
  margin-right: 15px;
  font-weight: bold;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header .flxR .subnav .left .tel2 {
    font-size: 18px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .flxR .subnav .left .tel2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 640px) {
  #header .flxR .subnav .left .tel2 a {
    color: #fff;
  }
}

#header .flxR .subnav .left .tel2 .tel_01 {
  font-weight: bold;
  padding: 5px 0;
}

#header .flxR .subnav .left .tel2 .tel3 {
  font-weight: bold;
  padding: 5px 0;
}

@media only screen and (max-width: 640px) {
  #header .flxR .subnav .left .tel2 {
    margin-right: 0;
  }
  #header .flxR .subnav .left .tel2 a {
    color: #fff;
    font-size: 20px;
    display: block;
  }
  #header .flxR .subnav .left .tel2 span {
    display: none;
  }
}

#header .flxR .subnav .left .tel2 a {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

#header .flxR .subnav .left .tel2 i {
  font-size: 26px;
  margin-right: 10px;
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header .flxR .subnav .left .tel2 i i {
    font-size: 18px;
  }
}

#header .flxR .subnav .right .hed_cont {
  color: #fff;
  font-size: 24px;
}

#header .flxR .subnav .right .hed_cont a {
  background: #f39700;
  border-radius: 50px;
  padding: 35px;
  display: block;
  font-weight: bold;
  color: #fff;
}

#header .flxR .subnav .right .hed_cont a:hover {
  background: #2a5cae;
}

@media only screen and (max-width: 834px) {
  #header .flxR .subnav .right .hed_cont span {
    display: none;
  }
  #header .flxR .subnav .right .hed_cont a {
    border-radius: 50%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header .flxR .subnav .right .hed_cont {
    font-size: 18px;
  }
}

#header .flxR .subnav .right .hed_cont i {
  font-size: 26px;
  margin-right: 10px;
}

@media only screen and (max-width: 834px) {
  #header .flxR .subnav .right .hed_cont i {
    margin-right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  #header .flxR .subnav .right .hed_cont i i {
    font-size: 18px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header .flxR .subnav .center {
    width: 37% !important;
  }
}

@media only screen and (max-width: 640px) {
  #header .flxR .subnav .center {
    width: 37% !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #header .flxR .subnav .center {
    width: 26% !important;
  }
}

#nav_global {
  z-index: 10;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#nav_global ul li a {
  line-height: 1;
  color: #583822 !important;
  display: block;
  font-size: 16px;
  font-weight: bold;
}

#nav_global ul li a:hover {
  opacity: 1;
  -webkit-animation: .3s;
  animation: zoom .3s;
}

.global-menu-btn {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .global-menu-btn {
    z-index: 11;
    display: block;
    position: absolute;
    left: 15px;
    top: 15px;
    color: #583822;
    line-height: 1;
  }
  .global-menu-btn::before {
    font-size: 2.4em;
    content: "\f0c9";
    line-height: 1;
    font-family: "Font Awesome 5 pro";
    font-weight: 400;
  }
  .global-menu-btn i {
    margin-top: 3px;
    display: block;
    line-height: 1;
  }
}

.global-menu-close-btn {
  display: none;
}

@media only screen and (max-width: 834px) {
  .global-menu-close-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #fff;
    -webkit-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
  }
  .global-menu-close-btn:before {
    content: "\f00d";
    line-height: 32px;
    font-family: "Font Awesome 5 pro";
    font-size: 1.3rem;
    font-weight: 300;
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .global-menu-close-btn i {
    display: none;
  }
}

@media only screen and (max-width: 834px) {
  #hednav {
    justify-content: center;
    height: 68px;
    width: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    margin: 0;
    padding: 10px 0;
    background: rgba(220, 237, 253, 0.7);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  }
  #hednav .wrap {
    visibility: hidden;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
    background: rgba(0, 0, 0, 0.6) !important;
    display: block !important;
    position: fixed;
    width: 100% !important;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  #hednav .wrap .navwrap {
    display: block !important;
    background: #fff;
    width: 50%;
    height: 100%;
    opacity: 0;
    -webkit-transform: translateX(-240%);
    -ms-transform: translateX(-240%);
    transform: translateX(-240%);
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }
  #hednav .wrap .navwrap #hed_info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
  #hednav .wrap .navwrap #hed_info span {
    line-height: 1;
    display: block;
    margin-top: 10px;
  }
  #hednav .wrap .navwrap #hed_info .store1 p {
    background: #892141;
  }
  #hednav .wrap .navwrap #hed_info .store2 p {
    border-left: 1px solid #fff;
    background: #892141;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn {
    width: 50%;
    margin: 0 !important;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a {
    padding: 10px 0;
    font-weight: 300;
    font-size: 0.5rem !important;
    border: 0 !important;
    color: #fff !important;
    transition: 1s;
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  #hednav .wrap .navwrap #hed_info .hoverbtn a i {
    font-weight: 300;
    font-size: 1.5rem !important;
    margin: 0 0 5px 0 !important;
  }
  #hednav .wrap .navwrap #nav_global {
    width: 100%;
    margin-top: 0 !important;
  }
  #hednav .wrap .navwrap #nav_global ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  #hednav .wrap .navwrap #nav_global ul li {
    position: relative;
    width: 100%;
    height: 80px;
    border-bottom: 2px dotted #f39700;
  }
  #hednav .wrap .navwrap #nav_global ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    line-height: 1;
    height: 100%;
    font-size: 1.2rem !important;
  }
}

.MenuOpened {
  z-index: 13 !important;
}

.MenuOpened .global-menu-btn {
  z-index: 0;
}

.MenuOpened .wrap {
  z-index: 10 !important;
  visibility: visible !important;
  opacity: 1 !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.MenuOpened .wrap .navwrap {
  opacity: 1 !important;
  -webkit-transform: translateX(0) !important;
  -ms-transform: translateX(0) !important;
  transform: translateX(0) !important;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh !important;
}

.MenuOpened .wrap .global-menu-close-btn {
  opacity: 1;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.mainwrap {
  position: relative;
}

.mainwrap .main_txt {
  position: absolute;
  top: 14%;
  left: 0;
  right: 0;
  padding: 10px;
}

.mainArea {
  position: relative;
  z-index: 0;
  width: 100%;
}

.mainArea .maintxt {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainArea .maintxt h1 {
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  text-shadow: 0px 3px 9.7px rgba(0, 0, 0, 0.35);
  font-size: 70px;
  font-size: 9.11458vw;
}

@media only screen and (min-width: 1600px) {
  .mainArea .maintxt h1 {
    font-size: 70px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainArea .maintxt h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 640px) {
  .mainArea .maintxt h1 {
    font-size: 35px;
  }
}

.mainArea .maintxt h1 span {
  display: block;
}

.mainArea img {
  max-width: 100%;
}

@media only screen and (max-width: 640px) {
  .mainArea img {
    width: 110% !important;
    height: 83vw !important;
    min-height: 230px !important;
    margin: 0 -27% !important;
    object-fit: cover;
  }
}

#local-keyvisual {
  position: relative;
  background-position: center;
}

#local-keyvisual h1 {
  z-index: 1;
  position: relative;
  color: #fff;
  font-weight: bold;
  line-height: 1.7em;
  text-align: center;
  font-size: 40px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 40px;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 35px;
  }
}

#local-keyvisual h1 span {
  background-position: center;
  background-size: cover;
  background: rgba(51, 51, 51, 0.1);
  padding: 150px 0;
  display: block;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 span {
    padding: 100px 0;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.pan1 {
  margin: 10px 0;
}

@media only screen and (max-width: 834px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 640px) {
  .pcnone {
    display: block;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  background: url(../images/common/ft_bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  color: #583822;
}

@media only screen and (max-width: 834px) {
  footer#global_footer {
    padding-bottom: 120px;
  }
}

footer#global_footer .inner {
  padding: 50px 0 0px;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 50px 0 157px;
  }
}

footer#global_footer a {
  color: #583822;
  text-decoration: none;
}

footer#global_footer a:hover {
  text-decoration: underline;
}

footer#global_footer #nav_footer {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  text-align: left;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer li a {
  font-weight: bold;
  text-decoration: none;
  padding: 8px;
  display: block;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.ft_left .address {
  font-weight: bold;
  text-align: left;
  margin-top: 20px;
}

@media only screen and (max-width: 834px) {
  .ft_left .address {
    text-align: center;
  }
}

.ft_left .tel2 {
  font-weight: bold;
  text-align: left;
}

@media only screen and (max-width: 834px) {
  .ft_left .tel2 {
    text-align: center;
  }
  .ft_left .tel2 a {
    font-weight: bold;
  }
}

.ft_left .jimushotel {
  font-weight: bold;
  text-align: left;
}

@media only screen and (max-width: 834px) {
  .ft_left .jimushotel {
    text-align: center;
  }
}

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
  }
}

.totop {
  position: fixed;
  bottom: -10px;
  right: 0;
  z-index: 5;
}

.totop img {
  width: 80%;
}

@media only screen and (max-width: 640px) {
  .totop {
    position: fixed;
    bottom: -10px;
    right: 0;
    width: 16%;
    z-index: 4;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .totop {
    position: fixed;
    bottom: -30px;
    right: 0;
    width: 16%;
    z-index: 3;
  }
}

/* box */
.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.h2_01 h2 {
  font-size: 28px;
  letter-spacing: 2px;
  line-height: 1.5;
  font-family: fot-seurat-pron, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #583822;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 20px;
  }
}

.sec_01 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dcedfd;
  flex-direction: row-reverse;
  padding: 40px;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_01 {
    padding: 20px;
  }
}

@media only screen and (max-width: 640px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .flxL {
    width: 100% !important;
  }
  .sec_01 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_01 .flxL {
  width: 30%;
}

.sec_01 .flxR {
  width: 40%;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    display: block;
  }
  .sec_02 .flxL {
    width: 100% !important;
  }
  .sec_02 .flxR {
    width: 100% !important;
  }
}

.sec_02 .flxL {
  width: 50%;
  background: url(../images/common/info_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 74px;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .sec_02 .flxL {
    padding: 41px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_02 .flxL {
    padding: 136px;
  }
}

.sec_02 .flxL:before {
  content: url(../images/common/koara.png);
  position: absolute;
  bottom: 0;
  left: 0;
}

.sec_02 .flxL .txt span {
  font-size: 26px;
  font-weight: bold;
}

.sec_02 .flxL .btn a {
  padding: 15px;
  color: #fff;
  font-weight: bold;
  background: #0099ff;
  border-radius: 50px;
  display: block;
  width: 250px;
  margin: 0 auto;
}

.sec_02 .flxR {
  width: 50%;
}

.sec_02 .flxR .gmap {
  margin-top: 30px;
}

.gmap iframe {
  width: 100%;
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_03 .flxL {
  background: #fff462;
  padding: 15px;
  width: 48%;
}

.sec_03 .flxL .txt_L {
  border: 2px dashed #fff;
  padding: 30px;
}

.sec_03 .flxL .txt_L span {
  font-size: 26px;
  font-weight: bold;
}

.sec_03 .flxR {
  background: #edcbd7;
  padding: 15px;
  width: 48%;
}

.sec_03 .flxR .txt_R {
  border: 2px dashed #fff;
  padding: 30px;
}

.sec_03 .flxR .txt_R span {
  font-size: 26px;
  font-weight: bold;
}

.newswrap {
  padding: 20px;
  position: relative;
}

.newswrap:before {
  content: url(../images/common/news_L.png);
  position: absolute;
  left: 0;
  top: -40px;
}

.newswrap:after {
  content: url(../images/common/news_R.png);
  position: absolute;
  right: 0;
  top: -40px;
}

.news {
  height: 300px;
  overflow-y: scroll;
  max-width: 650px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .news {
    max-width: 300px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .news {
    max-width: 450px;
  }
}

.news dl {
  border-bottom: 1px dashed #ffe2c6 !important;
  padding: 15px !important;
  display: block !important;
}

.news dt {
  border: none !important;
  padding: 0 !important;
  width: 100% !important;
}

.news dd {
  border: none !important;
  padding: 0 !important;
  width: 100% !important;
}

.bnrbox {
  z-index: 2;
  position: relative;
}

.bnrbox .box {
  width: 25%;
}

.bnrbox .box a {
  display: block;
}

.bnrbox2 {
  justify-content: center;
  position: relative;
}

.bnrbox2 .box {
  width: 30%;
  text-align: center;
  z-index: 3;
  position: relative;
}

.bnrbox2 .box a {
  display: block;
}

.bnrbox2:before {
  content: url(../images/common/kirin.png);
  position: absolute;
  bottom: 0;
  left: -30px;
  z-index: 1;
}

@media only screen and (max-width: 640px) {
  .bnrbox2:before {
    top: -260%;
    left: 0;
  }
}

.bnrbox2:after {
  content: url(../images/common/zou.png);
  position: absolute;
  bottom: 0;
  right: -40px;
  z-index: 1;
}

@media only screen and (max-width: 640px) {
  .bnrbox2:after {
    top: -210%;
    right: -2%;
  }
}

.instagram .sns_text {
  display: none !important;
}

.instagram div.sns_list {
  flex-direction: inherit;
  border: none !important;
}

@media only screen and (max-width: 640px) {
  .instagram div.sns_list {
    display: block !important;
  }
}

.instagram div.sns_list > div {
  border-bottom: none !important;
}

.instagram div.sns_list div.sns_photo {
  width: auto !important;
}

.home_td {
  margin-left: -7.69231% !important;
  display: inline-table;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td {
    margin-left: -7.69231% !important;
  }
}

@media only screen and (max-width: 640px) {
  .home_td {
    margin-left: 0 !important;
  }
}

.home_td .box {
  z-index: 10;
  float: left;
  width: 42.30769% !important;
  margin-left: 7.69231% !important;
  margin-bottom: 30px !important;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .home_td .box {
    width: 42.30769% !important;
    margin-left: 7.69231% !important;
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .home_td .box {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.home_td .box img {
  width: 100% !important;
  border-radius: 15px;
}

.home_td .box .cont_titl {
  display: block;
  margin: 30px 0 10px;
}

.home_td .box .cont_titl h3 {
  font-size: 25px;
  font-weight: bold;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3 {
    font-size: 20px;
  }
}

.home_td .box .cont_titl h3:first-letter {
  font-size: 35px;
  color: #ef9916;
}

@media only screen and (max-width: 640px) {
  .home_td .box .cont_titl h3:first-letter {
    font-size: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .h2_02 {
    margin-top: 50px;
  }
}

.h2_02 h2 {
  font-size: 28px;
  position: relative;
  padding: 0.6em;
  background: #fffacd;
  border-radius: 15px;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 20px;
  }
}

.h2_02 h2:after {
  position: absolute;
  content: '';
  top: 100%;
  left: 30px;
  border: 15px solid transparent;
  border-top: 15px solid #fffacd;
  width: 0;
  height: 0;
}

.h2_04 h2 {
  font-size: 28px;
  padding: 0.5em;
  /*文字周りの余白*/
  display: inline-block;
  /*おまじない*/
  line-height: 1.3;
  /*行高*/
  background: #dbebf8;
  /*背景色*/
  vertical-align: middle;
  border-radius: 25px 0px 0px 25px;
  /*左側の角を丸く*/
  width: 100%;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 {
    font-size: 20px;
  }
}

.h2_04 h2:before {
  content: '●';
  color: white;
  margin-right: 8px;
}

.h2_06 h2 {
  font-size: 23px;
  position: relative;
  padding: 10px 0;
}

@media only screen and (max-width: 834px) {
  .h2_06 h2 {
    font-size: 18px;
  }
}

.h2_06 h2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  background: -webkit-repeating-linear-gradient(-45deg, #edcbd7, #edcbd7 2px, #fff 2px, #fff 4px);
  background: repeating-linear-gradient(-45deg, #edcbd7, #edcbd7 2px, #fff 2px, #fff 4px);
}

.h3_01 h3 {
  font-size: 23px;
  position: relative;
  padding: 10px 0;
}

@media only screen and (max-width: 834px) {
  .h3_01 h3 {
    font-size: 18px;
  }
}

.h3_01 h3:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  background: -webkit-repeating-linear-gradient(-45deg, #edcbd7, #edcbd7 2px, #fff 2px, #fff 4px);
  background: repeating-linear-gradient(-45deg, #edcbd7, #edcbd7 2px, #fff 2px, #fff 4px);
}

.sec_04 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .flxL {
    width: 100% !important;
  }
  .sec_04 .flxR {
    width: 100% !important;
  }
}

.sec_04 .flxL {
  width: 48%;
}

.sec_04 .flxR {
  width: 48%;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .flxL {
    width: 100% !important;
  }
  .sec_05 .flxR {
    width: 100% !important;
    margin-top: 100px;
  }
}

.sec_05 .flxL {
  width: 48%;
  border: 2px solid #0099ff;
  border-radius: 18px;
}

.sec_05 .flxL .txt {
  padding: 30px;
}

.sec_05 .flxR {
  width: 48%;
  border: 2px solid #0099ff;
  border-radius: 18px;
}

.sec_05 .flxR .txt {
  padding: 30px;
}

.sec_05 .h2_03 h2 {
  font-size: 28px;
  color: #fff;
  background: #0099ff;
  padding: 30px;
  border-radius: 15px 15px 0 0;
}

.sec_06 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_06 {
    display: block;
  }
  .sec_06 .table_07 {
    width: 100% !important;
  }
  .sec_06 .table_08 {
    width: 100% !important;
  }
  .sec_06 .img {
    width: 100% !important;
    margin-top: 30px;
  }
}

.sec_06 .table_07 {
  width: 48%;
}

.sec_06 .table_08 {
  width: 48%;
}

.sec_06 .img {
  width: 25%;
}

.sec_06 > div:nth-child(1) {
  display: none;
}

.txt01 {
  padding: 30px;
  background: #f8d58c;
  border: 2px dotted #f39700;
  border-radius: 15px;
}

.txt02 i {
  margin-right: 7px;
  color: #e3adc1;
}

.txt06 {
  display: flex !important;
}

@media only screen and (max-width: 834px) {
  .txt06 {
    display: block !important;
  }
  .txt06 .div {
    width: 100%;
  }
  .txt06 a {
    width: 100%;
  }
}

.txt06 div {
  width: 80%;
}

.txt06 a {
  width: 20%;
}

.txt03wrap {
  background: #f39700;
  padding: 15px;
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .txt03wrap {
    width: 100%;
  }
}

.txt03wrap .txt03 {
  border: 2px dashed #fff;
  padding: 30px;
}

.txt03wrap .txt03 a {
  display: block;
  color: #583822;
}

.txt03wrap .txt03 span {
  font-size: 26px;
  font-weight: bold;
}

.txt04 .tel2 {
  font-size: 28px;
  font-weight: bold;
}

#anc01 {
  padding-top: 200px;
  margin-top: -200px;
}

.table_01 {
  border-radius: 15px;
  border: 2px solid #e3adc1;
}

.table_01 dt {
  border: none !important;
  display: block !important;
  text-align: center;
  padding: 15px !important;
  font-weight: bold;
}

.table_01 dd {
  border: none !important;
}

.table_01 dl {
  border-bottom: 1px dotted #e3adc1;
}

.table_01 dl:nth-child(9) {
  border-bottom: none !important;
}

.table_02 {
  border-radius: 15px;
  border: 1px solid #ffe2c6;
  background: #dcedfd;
}

.table_02 dt {
  border: none !important;
}

.table_03 td {
  border: none !important;
  padding: 20px !important;
}

.table_04 {
  max-width: 400px;
  margin: 0 auto;
}

.table_04 dt {
  border: none !important;
}

.table_04 dd {
  border: none !important;
}

.table_04 dl {
  border-bottom: 1px dotted #f39700;
}

.table_05 {
  border-radius: 15px;
}

.table_07 dt {
  font-weight: bold;
  display: block !important;
  text-align: center;
}

.table_08 dt {
  font-weight: bold;
  display: block !important;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  .table_09 dt {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
  .table_09 dd {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
  .table_09 dl {
    display: block !important;
  }
}

@media only screen and (max-width: 640px) {
  .table_10 dt {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
  .table_10 dd {
    display: block !important;
    text-align: center;
    width: 100% !important;
  }
  .table_10 dl {
    display: block !important;
  }
}

.table_10 dd a {
  color: #583822;
}

.table_10 dd a:hover {
  color: #f39700;
  text-decoration: underline;
}

/* title */
.tdpd10 td {
  padding: 10px !important;
}

.slider2 img {
  margin: 0 auto;
}

.bg_100per_wrap {
  position: relative;
  padding: 100px 0 !important;
}

@media only screen and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 50px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/common/cont_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #BBBBBB;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  text-align: center;
  padding: 10px;
}
